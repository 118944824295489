import React from "react"
import MainLayout from "../components/MainLayout"
import PageHeading from "../components/PageHeading"
import ProductCard from "../components/ProductCard"
import styled from "styled-components"

import Orbitz20Image from "../images/Orbiz20-w260.jpg"
import Orbitz10Image from "../images/orbitz10-w260.jpg"

import IndexSection3 from "../components/IndexSection3"

import SEO from "../components/seo"
import Helmet from "react-helmet"

const MpageStyles = styled.div`
  #product-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`

const XsCompactSeriesPage = () => {
  return (
    <>
      <SEO title="Orbital Floor Machines | Timberline Cleaning Equipment" />
      <Helmet>
        {/* <title>
          Medium Walk Behind Floor Scrubbers | Timberline Cleaning Equipment
        </title> */}
        <description>
          Designed on top of a rugged steel frame, the XS-Series is the right
          size for close quarter cleaning.
        </description>
      </Helmet>
      <MainLayout>
        <PageHeading
          title="Orbital Floor Machines"
          subHeading="ORBITZ Series"
          description=" Our small area Orbital Floor Scrubbers will clean a variety of applications with easily interchangable pads."
          title1="Our small area Orbital Floor Scrubbers will clean a variety of applications with easily interchangable pads."

          //   path="17 - 20in (43 - 51cm)"
          //   tank="Sol: 10gal (38l) Rec: 10gal (38l)"
          //   time="Up to 31,496 sqft. Per Charge"
        />
        <MpageStyles>
          <div id="product-container">
            <ProductCard
              image={Orbitz10Image}
              titleSize="19px"
              title='10" Orbital Floor Machine - NANO'
              description="Cleaning Path - 10 in / 25 cm"
              link="/products/orbitz10-floor-machine"
            />
            <ProductCard
              image={Orbitz20Image}
              titleSize="20px"
              title='20" Orbital Floor Machine'
              description="Cleaning Path - 20 in / 51 cm"
              link="/products/orbitz20-floor-machine"
            />
          </div>
        </MpageStyles>
        <IndexSection3 title="Request a Free Demo at your Facility" />
      </MainLayout>
    </>
  )
}

export default XsCompactSeriesPage
